import React from "react"
import ResumeProject from "./resume-project"
import ResumeCompanyLogo from "./resume-company-logo"

const ResumeCompany = ({ sectionData, images }) => {

    var projectsWrapperId = `${sectionData.id}-projects-wrapper`

    return <div id={sectionData.id} className={`resume-company ${sectionData.classes}`} >
        <div className="resume-company-header d-flex flex-sm-wrap">
            <div className="resume-company-title">
                <h3 className="mb-0">{sectionData.position}</h3>
                <div className="subheading mb-3">
                    <a href={sectionData.companyLink}>{sectionData.company}</a>
                </div>
            </div>
            <ResumeCompanyLogo image={images[sectionData.companyLogo]}></ResumeCompanyLogo>
            <div className="resume-date text-md-right">
                <span className="text-primary">{sectionData.tenure}</span>
            </div>
        </div>
        <div key={`${projectsWrapperId}`} className={`resume-company-project ${projectsWrapperId}`}>
        {
            sectionData.projects?.map( project => {
                var projectId = `${sectionData.id}-${project.title}`
                return <ResumeProject key={projectId} project={project}></ResumeProject>
            })
        }
        </div>
    </div>
}

export default ResumeCompany
