import React from "react"
// import ResumeSkill from "./resume-skill"
// import ResumeProject from "./resume-project"

const ResumeSkills = ({ sectionData, images }) => {

    var sections = [<h2 key="skills-header" className="skills-header">{sectionData.title}</h2>]

    // console.log(sectionData)
    console.log(images)

    for( var i = 0; i < sectionData.content.length; i++) {
        var subsection = sectionData.content[i]
        sections.push(<div key={`${subsection.title}-header`} className="skillset">{subsection.title}</div>)
        var skills = <ul key={`${subsection.title}-list`} className="skilllist">
        {
            subsection.skillset?.map( skill => {
                // console.log(skill)
                if(skill.icon) {
                    return <li key={`${skill.name}-item`} className="skill-li">
                        <a href={skill.link} className="d-flex">
                            <i className={skill.icon}></i>
                            <span className="skill-name">{skill.name}</span>
                            <span className="skill-sep"> - </span>
                            <span className="skill-duration">{`${skill.start} - ${skill.end}`}</span>
                            <span className="skill-level">{skill.level}</span>
                        </a>
                    </li>
                } else {
                    // console.log(skill.iconSvg)
                    var image = images.find(element => element.name === skill.iconSvg)
                    // console.log(image)
                    return <li key={`${skill.name}-item`} className="skill-li">
                        <a href={skill.link} className="d-flex">
                            <img src={image ? image.publicURL : ""} alt=""></img>
                            <span className="skill-name">{skill.name}</span>
                            <span className="skill-sep"> - </span>
                            <span className="skill-duration">{`${skill.start} - ${skill.end}`}</span>
                            <span className="skill-level">{skill.level}</span>
                        </a>
                    </li>
                }
                // return <ResumeSkill skill={skill} images={images}></ResumeSkill>
                // return <li className="skill-li">
                //     <a href={skill.link} className="d-flex justify-content-center">
                //         <i className={skill.icon}></i>
                //         <span className="skill-name">{skill.name}</span>
                //         <span className="skill-sep"> - </span>
                //         <span className="skill-duration">{`${skill.start} - ${skill.end}`}</span>
                //     </a>
                // </li>
            })
        }
        </ul>

        sections.push(skills)
    }

    return sections
}

export default ResumeSkills
