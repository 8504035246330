import React from "react"
import { graphql } from "gatsby"
import {Helmet} from "react-helmet"
import Img from "gatsby-image"
import Navigation from "../components/navigation"
import ResumeCompany from "../components/resume-company"
import ResumeSkills from "../components/resume-skills"
import SEO from "../components/seo"
import "../scss/resume.scss"

class App extends React.Component {

    render() {
        const { data } = this.props;
        var page = data.allPagesJson.edges[0].node
        var images = page.images
        var profileImage = images[0].childImageSharp
        var pagePath = page.page_path
        var pagePathInner = pagePath + "Inner"

        return (
            <div key={pagePath}>
                <SEO title={page.title}></SEO>
                <Helmet>
                  <script src={'/js/jquery.js'}></script>
                  <script src={'/js/jquery-ui.js'}></script>
                  <script src={'/js/bootstrap.bundle.js'}></script>
                  <link href={'/css/devicon.min.css'} rel="stylesheet"></link>
                  <link href={'/css/fontawesome-all.min.css'} rel="stylesheet"></link>
                  <script src={'/js/fontawesome-all.js'} async></script>
                  <script src={'/js/resume.js'}></script>
                </Helmet>
                <Navigation navImage={profileImage}></Navigation>
                <div key={pagePathInner} className="container-fluid p-0">
                    {
                        page.sections?.map( (section, index) => {
                            var sectionIdIndex = `${section.id}-${index}`
                            var sectionIDHR = `${section.id}-hr`
                            switch(section.id) {
                                case "skills":
                                    console.log("Using Skills")
                                    return [
                                        <section key={section.id} id={section.id} className="resume-section p-3 p-lg-5 d-flex d-column">
                                            <div key={sectionIdIndex} className="my-auto">
                                                <ResumeSkills key="onlyone" sectionData={section} images={images}></ResumeSkills>
                                            </div>
                                        </section>,
                                        <hr key={sectionIDHR} className="m-0"></hr>
                                    ]
                                default:
                                    return [<section key={section.id} id={section.id} className="resume-section p-3 p-lg-5 d-flex d-column">
                                    <div key={sectionIdIndex} className="my-auto">
                                        {
                                            section.content?.map( subsection => {
                                                switch(subsection.type) {
                                                    case "h1":
                                                        return <h1 key="pageh1" className="mb-0" dangerouslySetInnerHTML={{__html: subsection.content}}></h1>
                                                    case "h2":
                                                        return <h2 key="pageh1" className="mb-5" dangerouslySetInnerHTML={{__html: subsection.content}}></h2>
                                                    case "div":
                                                        return <div key={subsection.key} className={subsection.classes} dangerouslySetInnerHTML={{__html: subsection.content}}></div>
                                                    case "p":
                                                        return <p key={subsection.key} className={subsection.classes} dangerouslySetInnerHTML={{__html: subsection.content}}></p>
                                                    case "image":
                                                        if(images[subsection.index].extension === "svg") {
                                                            return <img key={subsection.key} src={images[subsection.index].publicURL} alt=""></img>
                                                        } else {
                                                            return <Img key={subsection.key} fluid={images[subsection.index].childImageSharp.fluid}></Img>
                                                        }
                                                    case "resume-section":
                                                        return <ResumeCompany key={subsection.key} sectionData={subsection} images={images}></ResumeCompany>
                                                    case "ul":
                                                        return <ul key={subsection.key} className={subsection.classes} dangerouslySetInnerHTML={{__html: subsection.content}}></ul>
                                                    default:
                                                        return
                                                }
                                            })
                                        }
                                        </div>
                                    </section>,
                                    <hr key={sectionIDHR} className="m-0"></hr>]
                            }
                        })
                    }
                </div>
            </div>
        )
    }
}

export const query = graphql`
query {
    allPagesJson {
        edges {
            node {
                title
                page_path
                sections {
                    id
                    title
                    content {
                        id
                        type
                        key
                        title
                        classes
                        content
                        company
                        companyLink
                        companyLogo
                        position
                        tenure
                        projects {
                            id
                            title
                            start
                            end
                            description
                            subprojects {
                                title
                                targetId
                            }
                            technologies
                        }
                        skillset {
                            name
                            icon
                            iconSvg
                            link
                            start
                            end
                            level
                        }
                    }
                }
                images {
                    id
                    extension
                    name
                    publicURL
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
    }
}
`

export default App
