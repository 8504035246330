import React from "react"
import Img from "gatsby-image"

const Navigation = ({navImage}) => {
    return <nav className="navbar navbar-expand-sm navbar-expand-md navbar-expand-lg navbar-expand-xl navbar-dark bg-primary fixed-top" id="sideNav">
        <a className="navbar-brand js-scroll-trigger" href="#page-top" id="navImageAnchor">
            <span className="d-block d-lg-none">Benjamin Scannell</span>
            <span className="d-none d-lg-block">
                <Img className="img-fluid img-profile rounded-circle mx-auto mb-2" fluid={navImage.fluid}></Img>
            </span>
        </a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link js-scroll-trigger" href="#about">About</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link js-scroll-trigger" href="#experience">Experience</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link js-scroll-trigger" href="#education">Education</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link js-scroll-trigger" href="#skills">Skills</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link js-scroll-trigger" href="#interests">Interests</a>
                </li>
            </ul>
        </div>
    </nav>
}

export default Navigation
