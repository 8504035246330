import React from "react"
import Img from "gatsby-image"

const ResumeCompanyLogo = ({ image }) => {

    var logo

    if(image.extension === "svg") {
        logo = <div className="resume-company-logo d-flex flex-grow-1 justify-content-center">
            <img className="company-logo-svg" src={image.publicURL} alt="">
            </img>
        </div>
    } else {
        logo = <div className="resume-company-logo flex-grow-1 justify-content-center">
            <Img style={{"maxHeight":"5rem"}} className="company-logo-normal" fluid={image.childImageSharp.fluid}>
            </Img>
        </div>
    }

    return logo
}

export default ResumeCompanyLogo
