import React from "react"

const ResumeProject = ({ project }) => {
    var subprojectWrapperClass = (project.subprojects != null && project.subprojects.length > 0) ? "project-subproject-wrapper" : "project-subproject-wrapper-hidden";
    var technologiesWrapperClass = (project.technologies != null && project.technologies.length > 0) ? "project-technologies-wrapper" : "project-technologies-wrapper-hidden";
    
    return <div key={project.title} id={project.id} className="resume-project">
        <div className="d-flex">
            <h6 dangerouslySetInnerHTML={{__html: project.title}}></h6>
            <span className="text-md-right">{project.start} - {project.end}</span>
        </div>
        <p className="project-description" dangerouslySetInnerHTML={{__html: project.description}}></p>
        <div className={`${subprojectWrapperClass}`}>
            <span className="project-subprojects-header">Dependencies:</span>
            <ul className="project-subprojects">
            {
                project.subprojects?.map( subproject => {
                    return <li key={`${project.id}-${subproject.targetId}`}><a href={"#" + subproject.targetId}>{subproject.title}</a></li>
                })
            }
            </ul>
        </div>
        <div className={`${technologiesWrapperClass}`}>
            <span className="project-technologies-header">Technologies:</span>
            <ul className="project-technologies">
            {
                project.technologies?.map( tech => {
                    return <li key={tech}>{tech}</li>
                })
            }
            </ul>
        </div>
    </div>
}

export default ResumeProject
